import { IApplicationApi, LoginResponse } from "../application-api";
import jwt_decode, { JwtPayload } from "jwt-decode";

const handleResponse = async (response: Response) => {
  let data: any;
  if (!response.ok) {
    const status = response.status;
    return Promise.reject(status);
  } else {
    try {
      const json =
        response.json &&
        (await response
          .clone()
          .json()
          .catch(() => response.text()));
      data = json.data ? json.data : json;
    } catch (error) {
      console.log(error);
      data = null;
    }

    return data;
  }
};

export class ApplicationRestApi implements IApplicationApi {
  private credentials: LoginResponse | null = null;
  private userRole: string;

  constructor(private url: string) {
    let localResponse = localStorage.getItem("loginResponse");
    this.userRole = "";
    if (localResponse) {
      this.credentials = JSON.parse(localResponse) as LoginResponse;
    }
    if (this.credentials?.token) {
      this.parseToken(this.credentials.token);
    }
  }

  isSuperAdmin(): boolean {
    return this.userRole === "SuperAdmin";
  }

  isAdmin(): boolean {
    return this.userRole === "Admin" || this.userRole === "Partner";
  }

  isPartner(): boolean {
    return this.userRole === "Partner";
  }

  isUser(): boolean {
    return this.userRole === "Member";
  }

  getCredentials(): LoginResponse | null {
    return this.credentials;
  }

  private parseToken(jwtToken: string) {
    let token = jwt_decode<JwtPayload>(jwtToken);
    let date = new Date();
    let now = (date.getTime() / 1000) | 0; //truncate
    let expiresIn = token?.exp && (token.exp ? token.exp - now : 0);

    if (
      token as any["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
    ) {
      this.userRole = (token as { [key: string]: string })[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ];
    }

    if (expiresIn && expiresIn > 0) {
      expiresIn *= 1000;
      setTimeout(() => {
        //logout after token expires
        this.credentials = null;
        window.location.reload();
      }, expiresIn);
    } else {
      this.credentials = null;
    }
  }

  createAuthenticatorKey(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/authorization/createauthenticatorkey`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //login to WeSystems cloud service
  async login(
    username: string,
    password: string,
    code: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    return new Promise<LoginResponse>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password, code, rememberMe }),
      };

      fetch(`${this.url}/api/authorization/login`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          this.credentials = response;
          this.parseToken(response.token);
          localStorage.setItem("loginResponse", JSON.stringify(response));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async loginAuthneticationCode(
    username: string,
    password: string,
    code: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    return new Promise<LoginResponse>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password, code, rememberMe }),
      };

      fetch(
        `${this.url}/api/authorization/loginauthneticationcode`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          this.credentials = response;
          this.parseToken(response.token);
          localStorage.setItem("loginResponse", JSON.stringify(response));
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async verifyAuthenticationCode(code: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: '"' + code + '"',
      };

      fetch(
        `${this.url}/api/authorization/verifyauthneticationcode`,
        requestOptions
      )
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async forgotPassword(email: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/authorization/resetpassword/?userName=${email}`,
        requestOptions
      )
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async changePassword(token: string, password: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, password }),
      };

      fetch(`${this.url}/api/authorization/changepassword/`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async logout(): Promise<void> {
    return new Promise<void>((resolve) => {
      localStorage.removeItem("loginResponse");
      this.credentials = null;
      this.userRole = "";
      resolve();
    });
  }

  twoFactorEnabled: () => Promise<any> = () => {
    return new Promise<boolean>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(`${this.url}/api/authorization/twofactorenabled`, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  async getAccountTeam(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/data/accountteam?customerId=${customerId}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async getCustomers(
    page: number,
    limit: number,
    search: string,
    includeCustomers: boolean
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      const url =
        page === 0 && limit === 0
          ? `${this.url}/api/data/customers`
          : `${this.url}/api/data/customers?page=${page}&limit=${limit}&search=${search}&includeCustomers=${includeCustomers}`;

      fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getNocContacts(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/data/noccontacts?customerId=${customerId}`,
        requestOptions
      )
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async getTrunks(
    customerid: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      const url = `${this.url}/api/data/trunks?customerId=${customerid}&page=${page}&limit=${limit}&search=${search}`;

      fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getTelephoneNumbers(
    customerid: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      const url = `${this.url}/api/data/telephonenumbers?customerId=${customerid}&page=${page}&limit=${limit}&search=${search}`;

      fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getUsers(
    customerid: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      const url = `${this.url}/api/data/users?customerId=${customerid}&page=${page}&limit=${limit}&search=${search}`;

      fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getCustomerBillingPeriods(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      const url = `${this.url}/api/data/customerbillingperiods?customerId=${customerId}`;

      fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getSumCosts(
    customerId: number,
    billingPeriod: string,
    search: string,
    page: number,
    limit: number
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      const url = `${this.url}/api/data/sumcosts?customerId=${customerId}&billingPeriod=${billingPeriod}&search=${search}&page=${page}&limit=${limit}`;

      fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async getTrunkroviderRates(
    customerId: number,
    search: string,
    page: number,
    limit: number
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      const url = `${this.url}/api/data/trunkproviderrates?customerId=${customerId}&search=${search}&page=${page}&limit=${limit}`;

      fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async getDestinationDuration(
    customerId: number,
    billingPeriod: string,
    search: string,
    page: number,
    limit: number
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };
      search = encodeURIComponent(search);
      const url = `${this.url}/api/data/destinationduration?customerId=${customerId}&billingPeriod=${billingPeriod}&search=${search}&page=${page}&limit=${limit}`;

      fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async downloadReports(
    customerId: number,
    search: string,
    page: number,
    limit: number
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      search = encodeURIComponent(search);

      const url = `${this.url}/api/data/downloadreports?customerId=${customerId}&search=${search}&page=${page}&limit=${limit}`;

      fetch(url, requestOptions)
        .then(handleResponse)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async upsertReport(
    customerId: number,
    billedPeriod: string,
    type: number
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
        body: JSON.stringify({ customerId, billedPeriod, type, done: false }),
      };

      fetch(`${this.url}/api/data/upsertreport`, requestOptions)
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async deleteBillingPeriod(
    customerId: number,
    billingPeriod: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.credentials?.token,
        },
      };

      fetch(
        `${this.url}/api/data/customerbillingperiod?customerId=${customerId}&billingPeriod=${billingPeriod}`,
        requestOptions
      )
        .then(handleResponse)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
