import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import CustomersViewBar from "./CustomersViewBar";
import { ReactComponent as UserIcon } from "./icons/User.svg";
import { ReactComponent as HamburgerMenu } from "./icons/HamburgerMenu.svg";
import { ApplicationContext } from "../context/ApplicationContext";
import SideBar from "./SideBar";
import "./AppNavBar.css";
import { ReactComponent as CloseX } from "./icons/closeX.svg";

import {
  Container,
  DropDown,
  DropDownToggle,
  DropDownMenu,
  DropDownLink,
  DropDownDivider,
  DropDownButton,
  Navbar,
} from "@seveniteen/bootiquestrap-ui-library";

const AppNavBar: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const [isSideBarVisible, setIsSideBarVisible] = useState(false);
  const currentUser = applicationContext.webApi?.getCredentials()?.name ?? "";

  const logout = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    applicationContext.webApi?.logout();
    window.location.reload();
  };

  const renderCustomerBarView = () => {
    return <CustomersViewBar />;
  };

  return (
    <>
      <Navbar
        className={`bg-white app-navbar  ${isSideBarVisible ? "fade-in" : ""}`}
        containerClassName="d-flex justify-content-lg-end align-items-center h-100"
        style={{
          minHeight: "70px",
          transition: "1",
          // boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div className="d-flex flex-row justify-content-between w-100">
          <div className="d-flex flex-row d-lg-none">
            <button
              className="navbar-toggler border-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarToggleExternalContent"
              aria-controls="navbarToggleExternalContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setIsSideBarVisible(!isSideBarVisible)}
            >
              <HamburgerMenu
                fill="#36454f"
                style={{ height: "30px", width: "30px" }}
              />
            </button>
          </div>
          <div className="d-flex flex-row justify-content-end w-100">
            {/* {applicationContext.webApi?.isSuperAdmin() ? */}
            {renderCustomerBarView()}
            {/* : null}   */}
            <div>
              <DropDown>
                <DropDownToggle className="text-dark pe-0 border-0">
                  <UserIcon
                    fill="black"
                    style={{ height: "24px", width: "24px" }}
                  />
                </DropDownToggle>
                <DropDownMenu className="dropdown-menu-end">
                  <DropDownLink linkClassName="disabled" to="#">
                    {currentUser ?? ""}
                  </DropDownLink>
                  <DropDownDivider />
                  <DropDownLink to="/authenticator">
                    2F Authentication
                  </DropDownLink>
                  <DropDownDivider />
                  <DropDownButton onClick={logout}>Logout</DropDownButton>
                </DropDownMenu>
              </DropDown>
            </div>
          </div>
        </div>
      </Navbar>
      <div className={`border overlay${isSideBarVisible ? " show" : ""}`}>
        <div className="p-0 d-lg-none">
          <SideBar />
        </div>
        <button
          className="btn button-close "
          type="button"
          onClick={() => {
            setIsSideBarVisible(!isSideBarVisible);
          }}
        >
          <CloseX className="closeX" fill="dark" />
        </button>
      </div>

      <Container className="h-100 bg-body" fluid>
        <div
          style={{
            height: "calc(100vh - 80px)",
            overflowY: "auto",
          }}
        >
          <Outlet />
        </div>
      </Container>
    </>
  );
};

export default AppNavBar;
