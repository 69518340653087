export type LoginResponse = {
  token: string;
  name: string;
  email: string;
  customer: number;
};

export interface IApplicationApi {
  getCredentials(): LoginResponse | null;
  isSuperAdmin(): boolean;
  isAdmin(): boolean;
  isUser(): boolean;
  isPartner(): boolean;
  createAuthenticatorKey(): Promise<string>;
  login: (
    username: string,
    password: string,
    code: string,
    rememberMe: boolean
  ) => Promise<LoginResponse>;
  loginAuthneticationCode(
    username: string,
    password: string,
    code: string,
    rememberMe: boolean
  ): Promise<LoginResponse>;
  verifyAuthenticationCode(code: string): Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  changePassword: (token: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  twoFactorEnabled: () => Promise<any>;
  getAccountTeam: (customerId: number) => Promise<any>;
  getCustomers: (
    page: number,
    limit: number,
    search: string,
    includeCustomers: boolean
  ) => Promise<any>;
  getNocContacts: (customerId: number) => Promise<any>;
  getTrunks: (
    customerid: number,
    page: number,
    limit: number,
    search: string
  ) => Promise<any>;
  getTelephoneNumbers: (
    customerid: number,
    page: number,
    limit: number,
    search: string
  ) => Promise<any>;
  getUsers: (
    customerid: number,
    page: number,
    limit: number,
    search: string
  ) => Promise<any>;
  getCustomerBillingPeriods: (customerId: number) => Promise<any>;
  getSumCosts: (
    customerId: number,
    billingPeriod: string,
    search: string,
    page: number,
    limit: number
  ) => Promise<any>;
  getTrunkroviderRates: (
    customerId: number,
    search: string,
    page: number,
    limit: number
  ) => Promise<any>;
  getDestinationDuration: (
    customerId: number,
    billingPeriod: string,
    search: string,
    page: number,
    limit: number
  ) => Promise<any>;
  downloadReports: (
    customerId: number,
    search: string,
    page: number,
    limit: number
  ) => Promise<any>;
  upsertReport: (
    customerId: number,
    billingPeriod: string,
    type: number
  ) => Promise<void>;
  deleteBillingPeriod: (
    customerId: number,
    billingPeriod: string
  ) => Promise<void>;
}

export class ApplicationApi<T extends IApplicationApi>
  implements IApplicationApi
{
  constructor(private apiService: T) {}

  isSuperAdmin(): boolean {
    return this.apiService.isSuperAdmin();
  }

  isAdmin(): boolean {
    return this.apiService.isAdmin();
  }

  isUser(): boolean {
    return this.apiService.isUser();
  }

  isPartner(): boolean {
    return this.apiService.isPartner();
  }

  getCredentials(): LoginResponse | null {
    return this.apiService.getCredentials();
  }

  async createAuthenticatorKey(): Promise<string> {
    return this.apiService.createAuthenticatorKey();
  }

  async login(
    username: string,
    password: string,
    code: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    return this.apiService.login(username, password, code, rememberMe);
  }

  async loginAuthneticationCode(
    username: string,
    password: string,
    code: string,
    rememberMe: boolean
  ): Promise<LoginResponse> {
    return this.apiService.loginAuthneticationCode(
      username,
      password,
      code,
      rememberMe
    );
  }

  async verifyAuthenticationCode(code: string): Promise<void> {
    return this.apiService.verifyAuthenticationCode(code);
  }

  async forgotPassword(email: string): Promise<void> {
    return this.apiService.forgotPassword(email);
  }

  async changePassword(token: string, password: string): Promise<void> {
    return this.apiService.changePassword(token, password);
  }

  async logout(): Promise<void> {
    return this.apiService.logout();
  }

  async twoFactorEnabled(): Promise<any> {
    return this.apiService.twoFactorEnabled();
  }

  async getAccountTeam(customerId: number): Promise<any> {
    return this.apiService.getAccountTeam(customerId);
  }

  async getCustomers(
    page: number,
    limit: number,
    search: string,
    includeCustomers: boolean
  ): Promise<any> {
    return this.apiService.getCustomers(page, limit, search, includeCustomers);
  }

  async getNocContacts(customerId: number): Promise<any> {
    return this.apiService.getNocContacts(customerId);
  }

  async getTrunks(
    customerid: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return this.apiService.getTrunks(customerid, page, limit, search);
  }

  async getTelephoneNumbers(
    customerid: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return this.apiService.getTelephoneNumbers(customerid, page, limit, search);
  }

  async getUsers(
    customerid: number,
    page: number,
    limit: number,
    search: string
  ): Promise<any> {
    return this.apiService.getUsers(customerid, page, limit, search);
  }

  async getCustomerBillingPeriods(customerId: number): Promise<any> {
    return this.apiService.getCustomerBillingPeriods(customerId);
  }

  async getSumCosts(
    customerId: number,
    billingPeriod: string,
    search: string,
    page: number,
    limit: number
  ): Promise<any> {
    return this.apiService.getSumCosts(
      customerId,
      billingPeriod,
      search,
      page,
      limit
    );
  }

  async getTrunkroviderRates(
    customerId: number,
    search: string,
    page: number,
    limit: number
  ): Promise<any> {
    return this.apiService.getTrunkroviderRates(
      customerId,
      search,
      page,
      limit
    );
  }

  async getDestinationDuration(
    customerId: number,
    billingPeriod: string,
    search: string,
    page: number,
    limit: number
  ): Promise<any> {
    return this.apiService.getDestinationDuration(
      customerId,
      billingPeriod,
      search,
      page,
      limit
    );
  }

  async downloadReports(
    customerId: number,
    search: string,
    page: number,
    limit: number
  ): Promise<any> {
    return this.apiService.downloadReports(customerId, search, page, limit);
  }
  async upsertReport(
    customerId: number,
    billingPeriod: string,
    type: number
  ): Promise<void> {
    return this.apiService.upsertReport(customerId, billingPeriod, type);
  }

  async deleteBillingPeriod(
    customerId: number,
    billingPeriod: string
  ): Promise<void> {
    return this.apiService.deleteBillingPeriod(customerId, billingPeriod);
  }
}
