import React from "react";
import SideBar from "./SideBar";
import AppNavBar from "./AppNavBar";
import "./Layout.css";

const Layout: React.FC = () => {
  return (
    <div className="d-flex flex-row ">
      <SideBar />
      <div className="layout-container-right d-flex flex-column w-100 vh-100 ">
        <AppNavBar />
      </div>
    </div>
  );
};

export default Layout;
