import React from "react";
import { ReactComponent as VoiceManagment } from "../icons/voiceManagment.svg";
import { ReactComponent as Network } from "../icons/Network3.svg";
import { ReactComponent as Computing } from "../icons/Computing.svg";
import { ReactComponent as Administration } from "../icons/Administration.svg";
import { ReactComponent as Internal } from "../icons/Internal.svg";
import { ReactComponent as ChevronDown } from "../icons/ChevronDown.svg";
import { ReactComponent as ChevronUp } from "../icons/ChevronUp.svg";
import { ReactComponent as Users } from "../icons/Users.svg";
import { ReactComponent as Customers } from "../icons/Customers.svg";
import { ReactComponent as Customers2 } from "../icons/Customers-Lilac.svg";
import { ReactComponent as ContactList } from "../icons/Contactlist.svg";
import { ReactComponent as CallIcon } from "../icons/call-icon.svg";
import { ReactComponent as MailIcon } from "../icons/mail-icon.svg";
import { ReactComponent as SolidLock } from "../icons/SolidLock.svg";
import { ReactComponent as Graph } from "../icons/Graph.svg";
import { ReactComponent as HamburgerMenu } from "../icons/icons8-hamburger.svg";
import { ReactComponent as CloseX } from "../icons/closeX.svg";
import { ReactComponent as UploadIcon } from "../icons/Upload.svg";
import { ReactComponent as Download } from "../icons/Download.svg";

export interface IconProps {
  className?: string;
  style?: React.CSSProperties;
  fill?: string;
  name?: string;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
  let tempIcon: React.JSX.Element = <></>;

  switch (props.name) {
    case "voicemanagment":
      tempIcon = (
        <VoiceManagment
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "network":
      tempIcon = (
        <Network
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "computing":
      tempIcon = (
        <Computing
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "administration":
      tempIcon = (
        <Administration
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "internal":
      tempIcon = (
        <Internal
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "chevrondown":
      tempIcon = (
        <ChevronDown
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "chevronup":
      tempIcon = (
        <ChevronUp
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "users":
      tempIcon = (
        <Users
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "customers":
      tempIcon = (
        <Customers
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "customers2":
      tempIcon = (
        <Customers2
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;

    case "contactList":
      tempIcon = (
        <ContactList
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "callIcon":
      tempIcon = (
        <CallIcon
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );

      break;
    case "mailIcon":
      tempIcon = (
        <MailIcon
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );

      break;

    case "solidLock":
      tempIcon = (
        <SolidLock
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );

      break;
    case "voicemail":
      tempIcon = (
        <Graph
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );

      break;

    case "uploadIcon":
      tempIcon = (
        <UploadIcon
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );

      break;

    case "hamburgerMenu":
      tempIcon = (
        <HamburgerMenu
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );

      break;

    case "closeX":
      tempIcon = (
        <CloseX
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    case "downloads":
      tempIcon = (
        <Download
          className={props.className}
          style={props.style}
          fill={props.fill}
        />
      );
      break;
    default:
      break;
  }
  return tempIcon;
};

export default Icon;
