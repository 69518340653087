import React, { useState, useContext, useCallback } from "react";
import { ApplicationContext } from "../context/ApplicationContext";
import { Table, TableData } from "@seveniteen/bootiquestrap-ui-library";

const CustomersList: React.FC = () => {
  const applicationContext = useContext(ApplicationContext);
  const initialData: TableData = {
    total: 0,
    page: 1,
    limit: 10,
    columns: [],
    rows: [],
  };

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TableData>(initialData);

  const loadData = useCallback(
    (currentPage: number, step: number, search: string) => {
      setIsLoading(true);
      applicationContext.webApi
        ?.getCustomers(currentPage, step, search, false)
        .then((response) => {
          setIsLoading(false);
          setData(response);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    },
    [applicationContext.webApi]
  );

  return (
    <Table
      className="table table-sm"
      borderColor="primary"
      renderColumnsColor="secondary"
      spinnerColor="primary"
      spinnerTextCOlor="primary"
      plusBtnColor="primary"
      footerBorderColor="primary"
      data={data}
      loadData={loadData}
      responsive
      bordered
      isLoading={isLoading}
      tableInfo={{
        currentPage: 0,
        step: 0,
        search: "",
      }}
      setTableInfo={function (): void {
        throw new Error("Function not implemented.");
      }}
    />
  );
};

export default CustomersList;
