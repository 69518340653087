import React from "react";
import "./Dashboard.css";

const Dashboard: React.FC = () => {
  // Dummy data for the dropdown
  const dropdownItems = [
    { id: 1, label: "Sophia Miller", href: "#" },
    { id: 2, label: "Ethan Davis", href: "#" },
    { id: 3, label: "Olivia Wilson", href: "#" },
    { id: 4, label: "James Martinez", href: "#" },
    { id: 5, label: "Ava Anderson", href: "#" },
    { id: 6, label: "Alexander Taylor", href: "#" },
    { id: 7, label: "Mia Garcia", href: "#" },
    { id: 8, label: "William Rodriguez", href: "#" },
    { id: 9, label: "Isabella Lopez", href: "#" },
    { id: 10, label: "Liam Hernandez", href: "#" },
  ];

  return (
    <div className=" h-100">
      <div className="p-2 m-2 d-flex justify-content-between">
        <div className="d-flex flex-row align-items-center shadow w-100 justify-content-between px-3">
          <div className="d-flex flex-column">
            <h3 className="p-3 ">Dashboard</h3>
          </div>

          <div className="my-3 d-flex flex-column">
            <div className="dropdown-center d-flex">
              {/* <p className="px-3 my-3 fs-5">Customer </p> */}
              <button
                className="btn btn-secondary dropdown-toggle btn-customer"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                {" "}
                Customer{" "}
              </button>{" "}
              <ul className="dropdown-menu">
                {dropdownItems.map((item) => (
                  <li key={item.id}>
                    <a className="dropdown-item" href={item.href}>
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
