import React, {
  useState,
  useContext,
  useEffect,
  ChangeEvent,
  FormEvent,
  ReactElement,
} from "react";
import { useNavigate, Link } from "react-router-dom";
import { ApplicationContext } from "../context/ApplicationContext";
import { LoginResponse } from "../services/web-api/application-api";
import "./Login.css";

type RememberMe = {
  email: string;
  isOn: boolean;
};

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rememberme, setRememberme] = useState<boolean>(false);
  const [input, setInput] = useState<{ [key: string]: string }>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formError, setFormError] = useState<boolean>(false);

  const applicationContext = useContext(ApplicationContext);

  const navigate = useNavigate();

  useEffect(() => {
    const remembermeData = localStorage.getItem("rememberme");

    if (remembermeData) {
      const tempRememberMe: RememberMe = JSON.parse(remembermeData);
      setRememberme(tempRememberMe.isOn);
      if (tempRememberMe.isOn) {
        setInput({ ...input, username: tempRememberMe.email });
      }
    }
  }, [applicationContext.webApi, input]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");
    if (event.currentTarget.reportValidity()) {
      setIsLoading(true);

      localStorage.setItem(
        "rememberme",
        JSON.stringify({ email: input["username"], isOn: rememberme })
      );
      applicationContext.webApi
        ?.login(input["username"], input["password"], "", rememberme)
        .then((loginResponse: LoginResponse) => {
          applicationContext.setCustomerId(loginResponse.customer);
          navigate("/");
        })
        .catch((error) => {
          let errorMessage = "Unable to login! Error: " + error;

          if (error === 409) {
            navigate("/authentication", {
              state: {
                userName: input["username"],
                password: input["password"],
                rememberme: input["rememberme"],
              },
            });
          } else if (error === 403) {
            errorMessage = "Invalid username and/or password!";
          }

          setErrorMessage(errorMessage);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setFormError(true);
    }
  };

  const renderLoginSpinner = (): ReactElement => {
    return (
      <button
        type="submit"
        className="btn btn-tertriary justify-content-center w-100 text-dark"
      >
        {isLoading ? (
          <>
            <span
              className="spinner-border spinner-border-sm me-2"
              aria-hidden="true"
            ></span>
            Login
          </>
        ) : (
          "Login"
        )}
      </button>
    );
  };

  const renderLoginForm = () => {
    return (
      <form
        className={
          "needs-validation " +
          (formError === true ? "was-validated p-2" : " p-5")
        }
        onSubmit={handleSubmit}
        noValidate
      >
        {errorMessage && (
          <div className="text-danger w-100">
            <ul>
              <li>{errorMessage}</li>
            </ul>
          </div>
        )}
        <div className="d-flex justify-content-center p-4">
          <label className="">Login to your account</label>
        </div>
        <label className="text-primary pb-2">Email</label>
        <input
          type="email"
          className="form-control email mb-1"
          placeholder="Enter your email"
          name="username"
          onChange={handleOnChange}
          required
        />
        <span className="invalid-feedback mb-2">Email is required!</span>
        <p className="helper-text">You must use valid email address!</p>
        <label className="text-primary pb-2">Password</label>
        <input
          type="password"
          className="form-control password mb-1"
          placeholder="Password"
          name="password"
          onChange={handleOnChange}
          required
        />
        <span className="invalid-feedback mb-2">Password is required!</span>
        <p className="helper-text mb-2 pb-3">
          Password must be at leat 6 characters long
        </p>
        <div className="d-flex justify-content-between"></div>
        <div className="justify-content-center align-items-center pb-3">
          {renderLoginSpinner()}
        </div>
        <div className=" d-flex flex-column justify-content-center align-items-center justify-content-center">
          <div className="justify-content-center align-items-center justify-content-center">
            <text className="text-center text-muted align-items-center justify-content-center">
              - OR -
            </text>
          </div>
          <div>
            <small className="text-center">Dont have and account? </small>
            <Link to="/signup" className="text-muted ps-2">
              <text>Sign-up</text>
            </Link>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center w-100 h-100 position-fixed">
        <div className="d-flex bg-white m-auto">
          <div className="container d-flex flex-row justify-content-center p-5">
            <div
              className="d-flex justify-content-center align-items-center me-2 bg-light"
              style={{ width: "350px" }}
            >
              <img
                className="logo-img "
                src="/images/PanterRejserLogoHead.png"
                alt="PANTER LOGO"
                style={{ width: "150px", height: "150px" }}
              />
            </div>
            {renderLoginForm()}
          </div>
        </div>
      </div>
      {/* <div className="vh-100 vw-100 d-flex">
        <div className="container-fluid h-100">
          <div className="row h-100 justify-content-center align-items-center ">
            <div className="col-6 bg-light h-100 d-flex justify-content-center align-items-center">
              <img
                className="logo-img "
                src="/images/PanterRejserLogoHead.png"
                alt="PANTER LOGO"
              />
            </div>
            <div className="col-6 bg-white px-5 ">{renderLoginForm()}</div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Login;
